import enUs from './translations/en';
import { IntlProvider } from 'react-intl';
import React, { Suspense, lazy } from 'react';
import Spinner from './ui-kit/spinner';
import InternetExplorerGuard from './components/internet-explrer-guard';

import './config';
import './tailwind.output.css';

const PageLayout = lazy(() => import('./layout/default'));

const App = () => (
  <InternetExplorerGuard>
    <Suspense fallback={<Spinner />}>
      <IntlProvider locale="en" messages={enUs}>
        <PageLayout />
      </IntlProvider>
    </Suspense>
  </InternetExplorerGuard>
);

export default App;
