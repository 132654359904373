import React, { useEffect, useState } from 'react';

import './spinner.css';

interface Spinner$Props {
  show?: boolean;
  customStyle?: string;
}

const TIME_OF_LONG_REQUEST = 500;

export default function Spinner({
  show = true,
  customStyle = '',
}: Spinner$Props) {
  const [visibility, setVisibility] = useState(show);

  useEffect(() => {
    setTimeout(() => setVisibility(true), TIME_OF_LONG_REQUEST);
  });

  const cn = visibility ? 'spinner--show' : '';
  const style = customStyle ? customStyle : 'text-blue-600 w-24 h-24';

  return (
    <div className={`spinner ${cn}`}>
      <div className={`${style} spinner__loader`}>
        <svg viewBox="25 25 50 50" className="spinner__svg">
          <circle cx="50" cy="50" r="20" className="stroke-current" />
        </svg>
      </div>
    </div>
  );
}
