export default {
  liveChannelEmpty: 'No live events currently scheduled',
  liveEventFinished: 'Event is finished',
  liveEventPreparing: 'Please stand by. This live stream will begin shortly',
  liveEvents: 'Upcoming live events',
  liveOnAir: 'On Air',

  liveAccessTitle:
    "Enter your access code below to view the stream. If you don't have it, please contact the event organizer.",
  liveAccessInvalid: 'Invalid access code',

  next: 'Next',
  previous: 'Prev',
  submit: 'Submit',
};
