import React from 'react';

interface InternetExplorerGuard$Props {
  children: React.ReactNode;
}

export default function InternetExplorerGuard({
  children,
}: InternetExplorerGuard$Props) {
  if (!(window.document as any).documentMode) {
    return <div>{children}</div>;
  }

  return (
    <div style={{ padding: '6rem 0', textAlign: 'center' }}>
      <div
        style={{
          fontSize: '1.25rem',
          maxWidth: '30rem',
          display: 'inline-block',
        }}
      >
        <header
          style={{ fontWeight: 'bold', fontSize: '2rem', marginBottom: '2rem' }}
        >
          Time for a change…
        </header>
        <p>
          We’ve detected that you’re using Internet Explorer, which is no longer
          supported by Microsoft.
        </p>
        <p>
          To access this site, you’ll need to switch to a modern browser such as{' '}
          <a
            href="https://www.microsoft.com/en-us/edge"
            target="_blank"
            rel="noopener noreferrer"
          >
            Edge
          </a>
          ,{' '}
          <a
            href="https://www.google.com/intl/en/chrome/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome
          </a>{' '}
          or{' '}
          <a
            href="https://www.mozilla.org/en-US/firefox/new/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox
          </a>
          .
        </p>
        <p>
          These browsers are more secure, more reliable and will give you the
          best viewing experience.
        </p>
      </div>
    </div>
  );
}
