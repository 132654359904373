import { BASE_API_URL } from './constants';
import axios from 'axios';
import retry from 'axios-retry';
import toFrontendFormat from './transformers/to-frontend-format';

axios.defaults.baseURL = BASE_API_URL;

axios.interceptors.response.use((resp) => {
  resp.data = toFrontendFormat(resp.data);
  return resp;
});

(function () {
  let retries = 0;

  retry(axios, { retryCondition });

  function retryCondition() {
    return retries++ < 2;
  }
})();
