type M = (key: string) => string;

export default function convert<O extends {}, T extends O, R extends {}>(
  mapper: M
) {
  function _reducer(memo: any, [key, val]: [string, any]): R {
    const k = mapper(key) as keyof typeof memo;
    memo[k] = _convert(val);
    return memo as R;
  }

  function _convert(obj: T): R;
  function _convert(obj: Array<T>): Array<R>;
  function _convert(obj: T | Array<T>): any {
    if (obj instanceof Array) {
      return _carr(obj);
    }
    if (obj instanceof Object) {
      return _cobj(obj);
    }
    return obj;
  }

  function _cobj(obj: T): R {
    if (typeof obj === 'object') {
      return Object.entries(obj).reduce(_reducer, {} as R);
    }
    const r: any = obj;
    return r as R;
  }

  function _carr(obj: Array<T>): Array<R> {
    return obj.map(_cobj);
  }

  return _convert;
}
